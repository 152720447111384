/**
 * App
*/

import $ from "jquery";
import 'Components/micromodal.js';
import 'Components/accordion.js';
import AOS from "../../node_modules/aos/dist/aos";
import navbar from "Components/navbar";


document.addEventListener('DOMContentLoaded', function() {
    window.$ = $;

    if( typeof console === 'object' ) {
        const flame = String.fromCodePoint(0x1F525);
        const heart = String.fromCodePoint(0x1F49C);

        console.log('%c%s',
        'color: white; background: black; font-size: 18px;',
        '\n' +
        ' Made with ' + heart +' by Empowers Studio ' + flame + '\n' +
        ' Designed by –@Alexhehe94 \n' +
        ' Developed by -@whoiskamil & -@rish1s \n' +
        ' Visit us => https://twitter.com/EmpowersStudio ');
    }

    AOS.init({
        offset: -210,
    });

    navbar();

    $('a[href^="#"]').on('click',function(e) {
        e.preventDefault();
        var target = this.hash;
        var header = $('.header').height();
        var $target = $(target);
        $('html, body').stop().animate({
            'scrollTop': ($target.offset().top - header)
        }, 900, 'swing', function () {
            window.location.hash = target;
        });
    });

    $('.submit-form').click(function(){
        var urldata = $('#js_open-link :selected').val();
        window.open(urldata);
    });

});